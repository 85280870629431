<template>
  <CheckoutHeader allow-redirection class="sticky inset-x-0 top-0 z-10" />
  <div
    v-if="isLoading"
    class="bg-surface-default-mid flex h-screen items-center justify-center"
    data-test="loading-screen"
  >
    <RevLoadingScreen :text="i18n(translations.loading)" />
  </div>
  <div v-if="!isLoading" class="flex flex-col md:flex-row">
    <div class="flex grow flex-col items-center pt-48 md:pb-80">
      <div class="w-full max-w-[51.25rem] px-16">
        <slot />
      </div>
    </div>

    <div class="w-full px-16 py-48 md:w-[24.5rem] md:px-24">
      <slot name="aside" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import CheckoutHeader from '~/scopes/checkout/components/CheckoutHeader.vue'

import translations from './Layout.translations'

defineProps<{ isLoading?: boolean }>()

const i18n = useI18n()
</script>
